<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class=" vx-row w-full  ml-auto mr-auto">
    <div class="vx-col w-1/2 ">
      <span>
            <h4 class="mb-5">Barcode LAF</h4>
        </span>
      <div >
        <div class="row bg bg-light   card" style="padding-bottom: 60px;">
          <div class="col-md-10 offset-md-1">
            <div class="flex-container  w-full" >
              <div class="flex-item" style="flex: 2;">
                <vx-card style="max-height: 600px;overflow: scroll; width: 200px" >
                  <img  v-for="(src, index) in optionImageThub" :key="index" :src="src" width="150px" alt="page-1" @click.stop="previewImage(index)"
                        :class="{ selected: selectedImageIndex === index }"   />
                  <span  v-if="!optionImageThub" class="box-loading"  >Loading..</span>
                </vx-card>
              </div>
              <div class="flex-item" style="flex: 8;">
                <div class="vs-row w-[600px]">
                  <vue-cutter
                      class="vx-col w-[600px]"
                      ref="cutter"
                      :container-bounding="optionCutter.bounding"
                      :crop-box-bounding="optionCutter.cropB"
                      :crop-dividing-line="true"
                      :src="optionCutter.src"
                      :crop-info="true"
                      mode="contain"
                      @preview="preview"
                      @imgLoad="imgLoad"
                  ></vue-cutter>
                  <vs-row
                      style="top: -70px;left: -60px; position: relative"
                      vs-align="flex-start"
                      vs-type="flex" vs-justify="center" vs-w="12">
                    <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                      <vs-button radius color="primary" class=" m-1" type="filled" icon="undo"  @click.stop="doRotate(-1)" ></vs-button>
                      <vs-button radius color="success" class=" m-1"  type="filled" icon="autorenew"  @click.stop="doRotate(0)" ></vs-button>
                      <vs-button radius color="primary" class=" m-1"  type="filled" icon="redo"  @click.stop="doRotate(1)"> </vs-button>
                    </vs-col>
                  </vs-row>
                </div>
                <div class="showBox p-2" style="top:-60px; position: relative" v-if="imageload" >
                  <vs-button class="float-right ml-2"
                             :disabled="disabled_detail || ocr_var===null"
                             readonly="readonly" @click.stop="getOcr"> OCR</vs-button>
                  <div class="imgCutDownBoxContainer " v-html="preHtml"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-col w-1/2 mt-2">
    <span>
      <h4 class="mb-5 mt-4"></h4>
    </span>

      <div class="vx-row mt-3" v-if="dataAll">
        <div class="vx-col w-full mb-2">
          <vs-input
              class="w-full "
              label="LAF"
              name="laf"
              :disabled="disabled_detail"
              v-model="dataAll.laf"
              @click.stop="setFocusedInput('laf')"
          />
        </div>

        <div class="vx-col w-full mb-2">
          <vs-input
              class="w-full "
              label="No. OGP"
              name="no_ogp"
              :disabled="disabled_detail"
              v-model="dataAll.no_ogp"
              @click.stop="setFocusedInput('no_ogp')"
          />
        </div>
      </div>

      <div class="vx-row mb-2 mt-6" v-if="!disabled_detail">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2"
                     :disabled="disabled_detail"
                     :readonly="disabled_detail" @click.stop="handleUpdate">Update
          </vs-button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Datepicker from "vuejs-datepicker";
import {VueCutter} from 'vue-cutter'
import {Int} from "vuecode.js";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    Datepicker,
    VueCutter,
  },
  props: {

    fileName: {
      type: String,
    },
    dataAll: {
      type: Object
    },
    page: {
      type: Int,
    },
    pageCount: {
      type: Int,
    },
    piId: {
      type: Number,
    },
    createdDate: {
      type: String,
    },
    ocr_value: {
      type: String,
    },
    disabled_detail: {
      type: Boolean,
    },
  },
  created() {
    this.selectedPages = [this.pageOptions[0]];
  },
  data() {
    return this.initialState();
  },
  methods: {
    getValueFromObject(objJson, keyVar){
      if (objJson && typeof objJson!== 'undefined' && objJson!==""){
        let jsonData = JSON.parse(objJson)
        return  jsonData[keyVar]
      }
      return ""
    },
    setFocusedInput(varName){
      if (this.dataAll[varName] ===""){
        this.dataAll[varName]=" "
      }
      this.ocr_var = varName;
    },
    previewImage(idx){
      this.optionCutter.src = this.optionImageThub[idx];
      this.selectedImageIndex = idx;
    },
    doRotate(mode){
      if (mode===-1){
        this.$refs.cutter.rotateLeft()
      }else if (mode===1){
        this.$refs.cutter.rotateRight()
      }else {
        this.$refs.cutter.rotateClear()
      }
    },
    resetOcr(){
      this.ocr_var = null
    },
    getOcr() {
      if (!this.ocr_var) {
        return
      }
      let varData =this.ocr_var
      this.$refs.cutter.getBase64Data(data => {
        let image64 = data.split(",")
        this.getTextOcr(image64[1], varData)
      })
    },
    handleSelectionChangePage(newValues) {
      this.getPdfS3Image(newValues.page);
    },
    customLabelPage(option) {
      return `Page ${option.page}`;
    },
    addDays(dateStr, days) {
      return moment(dateStr).add(days, 'days').format('YYYY-MM-DD');
    },
    initialState() {
      return {
        ocr_var:null,
        isFixed:false,
        selectedPages: [],
        pageOptions: Array.from({length: this.pageCount}, (_, index) => ({page: index + 1})),
        optionCutter: {
          bounding: '600px',
          src: "",
          cropB: '20%',
          imageload: ''
        },
        imageload: false,
        optionImageThub: null,
        showLoader: true,
        preHtml: '',
        remotePic: '',
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        topDate: 0,
      };
    },
    preview(data) {
      this.preHtml = data.html
    },
    imgLoad(params) {
      this.optionCutter.imageload = params
    },
    paramData() {
      return {
        pi_id: this.piId,
        laf: this.dataAll["laf"],
        no_ogp: this.dataAll["no_ogp"] ,
        status: this.dataAll["status"]
      };
    },
    postData() {
      this.$http
          .put(`/api/v1/ocr/laf/${this.piId}`, this.paramData())
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    paramUpload() {
      var form = new FormData()
      form.append('pi_id', this.piId)
      form.append('file', this.file)
      return form
    },
    paramImageS3(currPage) {
      var form = new FormData()
      form.append('pdf_name', this.fileName)
      form.append('page', currPage)
      return form
    },
    paramImage64(image64, text_type) {
      var form = new FormData()
      form.append('image64', image64)
      form.append('text_type', text_type)
      return form
    },
    isEmptyObject(obj) {
      return JSON.stringify(obj) === '{}';
    },
    getTextOcr(image64,varData) {
      if (image64 === null) {
        return
      }
      this.$vs.loading()
      this.$http
          .post("/api/v1/ocr/image64", this.paramImage64(image64, "TEXT"), null)
          .then((resp) => {
            this.ocr_value = resp.data;
            if (this.dataAll[varData]) {
              this.dataAll[varData] = this.ocr_value;
            }
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    renameOrAddAttributes(arr, attrMap) {
      // Check if the input is an array
      if (!Array.isArray(arr)) {
        arr= [arr] ;
      }

      if (typeof attrMap !== 'object' || attrMap === null) {
        return null
      }

      return arr.map(item => {
        if (typeof item !== 'object' || item === null) {
          return null
        }

        const newItem = { ...item }; // Clone the item to avoid mutating the original item directly

        for (let oldAttr in attrMap) {
          const newAttr = attrMap[oldAttr];
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty(oldAttr)) {
            newItem[newAttr] = item[oldAttr];
            delete newItem[oldAttr];
          } else {
            newItem[newAttr] = '0'; // Add new attribute with an empty value if old attribute doesn't exist
          }
        }

        return newItem;
      });
    },
    convertDate(dateStr) {
      // Periksa apakah formatnya sudah YYYY-MM-DD
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (regex.test(dateStr)) {
        return dateStr; // Format sudah benar, langsung return
      }

      let  formattedDate = "";
      if (dateStr.toString().indexOf("/")==-1){
        formattedDate = moment(dateStr).format('YYYY-MM-DD');
      }else {
        // Pisahkan string berdasarkan karakter "/"
        const [day, month, year] = dateStr.split('/');
        // Rekonstruksi menjadi format "YYYY-MM-DD"
        formattedDate = `${year}-${month}-${day}`;
      }
      return formattedDate;
    },
    getPdfS3Image(pages) {
      if (this.fileName === null || pages === null) {
        return
      }

      this.imageload = false;
      this.$vs.loading()
      this.$http
          .post("/api/v1/ocr/pdf-s3/image64/v2", this.paramImageS3(pages), null)
          .then((resp) => {
            this.imageload = true;
            if (resp.data) {
              this.optionImageThub = resp.data;
            }else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.error,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            // if (this.selectedImageIndex===-1)
            {
              this.selectedImageIndex=0;
              this.previewImage(this.selectedImageIndex);
            }
            this.$vs.loading.close()
            // this.optionCutter.src = `data:image/png;base64,${resp}`
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleUpdate() {
      // this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.type_submit = "update";
          this.$vs.loading();
          this.postData();
        }
      });
    },
  },
  mounted() {
    this.$vs.loading();
     setTimeout(() => {
      if (this.piId && this.fileName && this.dataAll) {
        let pages = Array.from({ length: this.pageCount+1 }, (_, i) => i + 1).join(', ');
        this.getPdfS3Image(pages)
      }
      this.$forceUpdate()
    }, 2000)

  },
  computed: {
  },
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getPdfS3Image(1)
    }
  },
};
</script>

<style scoped>

.c img {
  margin: 0.3rem 0.5rem;
}

.cc button {
  margin: 1rem 0.5rem;
}


.showBox {
  width: 600px;
  background: #dedede;
  border: 1px solid #dedede;
  border-radius: 5px;
  min-height: 50px;
}

.imgCutDownBoxContainer {
  border: 1px solid #ccc;
  background: #fff;
  text-align: left;
  overflow: hidden;
  border-radius: 5px;
}



.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
.fixed-div {
  position: fixed;
  top: 200px; /* Jarak dari atas halaman */
  left: 60px; /* Jarak dari kiri halaman */
  width: 47%; /* Lebar div */
  height: 200px; /* Tinggi div */
  padding: 10px; /* Padding dalam div */
  z-index: 1000; /* Z-index agar di atas elemen lain */
}
.target-div {
  height: 10px; /* Tinggi untuk membuat halaman scrollable */
  padding: 20px;
  margin-bottom: 20px;
}
.flex-container {
  display: flex;
}
.flex-item {
  padding: 0px;
}
.selected {
  border: 2px solid #968df3;
}
</style>
