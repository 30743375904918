<template>
  <div class="vx-row mb-12">

    <div v-if="!detail"
         v-bind:class="[
        detail ? detailShow + '  w-full mb-base' : '',
        detailHide,
      ]"
    >
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        not-click-selected
        open-expand-only-td
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th width="6%"   v-if="!disabled_detail ">
           <div class="text-center"> <vs-checkbox
              v-on:click="SelectAll()"
              v-model="statusSelectAll"
              color="primary"
            >Select All</vs-checkbox
            ></div>
          </vs-th>

          <vs-td> &nbsp; </vs-td>
          <vs-th sort-key="created_at">Scan Date</vs-th>
          <vs-th sort-key="release_at" v-if="tab_status==='release'" >Released Date</vs-th>
          <vs-th sort-key="reject_at" v-if="tab_status==='reject'" >Reject Date</vs-th>
          <vs-th sort-key="cancel_at" v-if="tab_status==='cancel'" >Cancel Date</vs-th>
          <vs-th sort-key="reject_at" v-if="tab_status==='inquiry'" >Reject Date</vs-th>
          <vs-th sort-key="update_at"   >Updated Date</vs-th>
          <vs-th>Invoice Date</vs-th>
          <vs-th>Receive Date</vs-th>
          <vs-th>Invoice Type</vs-th>
          <vs-th>Invoice Data</vs-th>
          <vs-th sort-key="barcode">Barcode</vs-th>
          <vs-th>Tax Data</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td  v-if="!disabled_detail">
              <div class="w-[150px]">
              <vs-checkbox
                v-on:click="
                  selectInvoice(
                    indextr,
                    data[indextr].id,
                    data[indextr]
                  )
                "
                :checked="inv_id_select.includes(tr.id)"
                color="primary"
              ></vs-checkbox>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip
                             :text="disabled_detail ? 'Show' : 'Edit'">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="
                      handleEdit(
                        data[indextr].id,
                        indextr,
                        data[indextr].data_valid,
                        data[indextr].dataJson,
                        data[indextr].file_name,
                        data[indextr].pages,
                        data[indextr].data_valid['product_item'],
                        data[indextr]
                      )
                    "
                    :icon="disabled_detail ? 'icon-eye' : 'icon-edit'"
                  />
                </vx-tooltip>
                <vx-tooltip v-if="!disabled_detail" text="Delete Draft">
                  <vs-button  v-if="data[indextr]['barcode']===''"
                    color="danger"
                    type="line"
                    icon-pack="feather"
                    @click="deleteDraft(data[indextr].id)"
                    icon="icon-x-circle"
                  />
                </vx-tooltip>
              </template>
            </vs-td>
            <vs-td>
              {{ dateFormat(data[indextr].scan_at) }}
            </vs-td>
            <vs-td v-if="tab_status==='cancel'">
              {{ dateFormatFull(data[indextr].cancel_at) }}
            </vs-td>
            <vs-td v-if="tab_status==='release'">
              {{ dateFormatFull(data[indextr].release_at) }}
            </vs-td>
            <vs-td v-if="tab_status==='reject'">
              {{ dateFormatFull(data[indextr].reject_at) }}
            </vs-td>
            <vs-td v-if="tab_status==='inquiry'">
              {{ dateFormatFull(data[indextr].reject_at) }}
            </vs-td>
            <vs-td >
              {{ dateFormatFull(data[indextr].updated_at ? data[indextr].created_at: data[indextr].updated_at  ) }}
            </vs-td>
            <vs-td>
              {{  (data[indextr].data_valid["dndate"]) }}
            </vs-td>
            <vs-td>
              {{ data[indextr].receive_at && data[indextr].receive_at !== '0001-01-01T00:00:00Z' ? dateFormat(data[indextr].receive_at) : '' }}
            </vs-td>
            <vs-td>
              {{ data[indextr].data_valid["invoice_type"] }}
            </vs-td>
            <vs-td>
              No : {{ data[indextr].data_valid["no"] }}<br/>
              D/N : {{ data[indextr].data_valid["dn"] }}<br/>
              D/N Date : {{  (data[indextr].data_valid["dndate"]) }}<br/>
              PO :{{ data[indextr].data_valid["po"] }}<br/>
              Ship To :{{ data[indextr].data_valid["shipto"] }}<br/>
              Due Date :{{  (data[indextr].data_valid["duedate"]) }}<br/>
            </vs-td>
            <vs-td>
              {{  (data[indextr]["barcode"]) }}
            </vs-td>
            <vs-td>
              Subtotal :  {{ numberFormat(data[indextr].data_valid["dpp"]) }}<br/>
              Tax : {{ numberFormat(data[indextr].data_valid["tax"]) }}<br/>
              Invoice Balance : {{ numberFormat(data[indextr].data_valid["gross"]) }}<br/>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <div class="vx-row mb-2 mt-2" v-if=" selected &&  selected.length > 0">
      <div class="vx-col w-full">
         <strong class="m-2" >Selected : </strong>{{ selected.length }}
      </div>
      <div class="vx-col w-full" >
        <vs-button class="m-2 " v-if="!disabled_detail" @click.stop="handleRelease">Released
        </vs-button>
        <vs-button class="m-2 bg-warning "  @click.stop="handleReject">Reject
        </vs-button>
        <vs-button class="m-2 bg-danger " v-if="!disabled_detail" @click.stop="handleCancel">Cancel
        </vs-button>
      </div>
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + '  w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            >Close
            </vs-button
            >
          </vs-col>

          <detail
            v-if="dataAll"
            @close="handleClose"
            :piId="piId"
            :data-j-s-o-n="dataJson"
            :data-all.sync="dataAll"
            @update:dataValid="dataValid = $event"
            :page-count="pageCount"
            :data-refer-to="dataValid['refers_to']"
            :data-valid.sync="dataValid"
            :file-name="fileName"
            :data-item="dataItem"
            :disabled_detail="disabled_detail"
          >
          </detail>
        </vs-row>
      </div>
    </transition>
    <div>
      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doRelease"
        @close="closeRelease"
        :active.sync="activePromptRelease"
      >
        <div class="con-exemple-prompt">
          Are you sure release draft invoice  ?
        </div>
      </vs-prompt>
      <vs-prompt
          color="danger"
          title="Confirmation"
          @cancel="val = ''"
          @accept="doCancel"
          @close="closeCancel"
          :active.sync="activePromptCancel"
      >
        <div class="con-exemple-prompt">
          Are you sure cancel draft invoice  ?
          <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <vs-input
                  v-validate="'required'"
                  class="w-full"
                  name="Note"
                  v-model="reason"
                  placeholder="Note"
              />
            </div>
          </div>
        </div>
      </vs-prompt>
      <vs-prompt
          color="danger"
          title="Confirmation"
          @cancel="val = ''"
          @accept="doReject"
          @close="closeReject"
          :active.sync="activePromptReject"
      >
        <div class="con-exemple-prompt">
          Are you sure Reject invoice  ?
          <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <vs-input
                  v-validate="'required'"
                  class="w-full"
                  name="Note"
                  v-model="reason"
                  placeholder="Note"
              />
            </div>
          </div>
        </div>
      </vs-prompt>
      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doDelete"
        @close="closeDelete"
        :active.sync="activePromptDelete"
      >
        <div class="vx-row mb-2">
        </div>
        <div class="con-exemple-prompt">
          Are you sure to Delete Draft invoice : {{ piId }} ?
        </div>
      </vs-prompt>
    </div>
  </div>
</template>
<script>
import Detail from "./detailv2.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Detail,
    Datepicker,
  },
  props: {
      optionSupplier:{
          type: Object
      },
    supplier_code: {
      type: String,
    },
    from_inv_date: {
      type: String,
    },
    to_inv_date: {
      type: String,
    },
    from_due_date: {
      type: String,
    },
    to_due_date: {
      type: String,
    },
    tab_status: {
      type: String,
    },
    disabled_detail: {
       type: Boolean,
    }
  },
  data() {
    return {
      reason:"",
      inv_id_select: [],
      selected: [],
      check_page: [],
      statusSelectAll: false,
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      statusView: false,
      deleteId: null,
      piId: null,
      fileName: null,
      dataItem: null,
      dataJson: null,
      dataAll: null,
      pageCount: 0,
      dataValid: null,
      invCode: "",
      vendor: null,
      poCode: null,
      index1: 0,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "created_at",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      activePromptRelease : false,
      activePromptReject : false,
      activePromptCancel: false,
      activePromptDelete : false,
      activePrompt: false,
      activePromptReversal: false,
      createdDate: null,
      dueDate: null,
      invDate: null,
      trNumber: null,
      trDate: null,
      reversal_date: new Date(),
      disabledAfterToday: {
        from: new Date(), // Disable all dates after specific date
      },

      selectedItems: [],
      selectAll: false,
      isAllSelected: false,
      checked: [],
      selectable: true, // Enable row selection
    };
  },
  methods: {
    selectInvoice(index, inv_id,data_inv) {
      if (!this.inv_id_select.includes(inv_id)) {
        this.inv_id_select.push(inv_id);
        this.selected.push(data_inv);
      } else {
        var index2 = this.inv_id_select.indexOf(inv_id);
        if (index2 !== -1) {
          this.inv_id_select.splice(index2, 1);
          this.selected.splice(index2, 1);
        }
      }
    },
    SelectAll() {
      let _this = this;
      if (!this.statusSelectAll){
        this.check_page.push(this.table.page)
          this.table.data.forEach(function (element) {
              if (!_this.inv_id_select.includes(element.id)) {
                  _this.inv_id_select.push(element.id);
                  _this.selected.push(element)
              }
          });

      }else{
        var index3 = this.check_page.indexOf(this.table.page);
        if (index3 !== -1) {
          this.check_page.splice(index3, 1);
        }
        this.table.data.forEach(function (element) {
            if (_this.inv_id_select.includes(element.id)) {
                var index2 = _this.inv_id_select.indexOf(element.id);
                if (index2 !== -1) {
                  _this.inv_id_select.splice(index2, 1);
                  _this.selected.splice(index2, 1);
                }
            }
        });

      }
    },
    handleReject(){
      if (this.selected && this.selected.length > 0) {
        this.activePromptReject = true
      }
    },
    handleCancel(){
      if (this.selected && this.selected.length > 0) {
        this.activePromptCancel = true
      }
    },
    doCancel(){

      if (this.reason===""){
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: `The notes is required `,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }
      const ids = this.selected.map(select => select.id);
      const dataValid = this.selected.map(select => select.data_valid);
      const dataPO = this.selected.map(select => select.data_po);
      let param = {
        id: ids,
        piID: dataPO,
        status: "cancel",
        reason: this.reason,
        data_valid: dataValid,
      };
      console.log(param,"Param")
      this.$vs.loading();
      this.$http
          .put(`/api/v1/ocr/invoices`,param)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }

            this.getData(true);
          })
          .catch(() => {
            this.$vs.loading.close();
          });

    },
    doReject(){

      if (this.reason===""){
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: `The notes is required `,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }
      const ids = this.selected.map(select => select.id);
      const dataValid = this.selected.map(select => select.data_valid);
      const dataPO = this.selected.map(select => select.data_po);
      let param = {
        id: ids,
        piID: dataPO,
        status: "reject",
        reason: this.reason,
        data_valid: dataValid,
      };
      this.$vs.loading();
      this.$http
          .put(`/api/v1/ocr/invoices`,param)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }

            this.getData(true);
          })
          .catch(() => {
            this.$vs.loading.close();
          });

    },
    closeReject(){
      this.activePromptReject = false
    },
    closeCancel(){
      this.activePromptCancel = false
    },
    handleRelease(){
      if (this.selected && this.selected.length > 0) {
        this.activePromptRelease = true
      }
    },
    doJsonParse(strJson){
      if (typeof  strJson !== "string") {
          return strJson
      }
      try{
        return JSON.parse(strJson)
      }catch {
        return {}
      }
    },
    inValidItem(items){
        if (items===null) {
          return true
        }
        if (typeof items ==="undefined"){
          return true
        }
        if (items ===""){
          return true
        }
        if (items.toString().startsWith("0001-01-01T")){
          return true
        }
        return  false
    },
    doRelease(){
      let isError = false
      let ErrorList = ""
      for (const selectedKey of this.selected) {
        if (this.inValidItem(selectedKey.data_valid)  ) {
          isError = true
          ErrorList = ErrorList + "<br>&bull; All required field is empty\n"
          break
        }

        if ( this.inValidItem(selectedKey.priority)) {
          ErrorList = ErrorList + "<br>&bull; Priority is empty, "
          isError = true
        }

          if ( this.inValidItem(selectedKey.data_valid.po)) {
              ErrorList = ErrorList + "<br>&bull; PO Number is empty, "
              isError = true
          }


          if ( this.inValidItem(selectedKey.data_valid.invoice_group)) {
              ErrorList = ErrorList + "<br>&bull; Trade Invoice is empty, "
              isError = true
          }


          if ( this.inValidItem(selectedKey.data_valid.no)) {
              ErrorList = ErrorList + "<br>&bull; Reference Number is empty, "
              isError = true
          }

          if ( this.inValidItem(selectedKey.barcode)) {
              ErrorList = ErrorList + "<br>&bull; Barcode is empty, "
              isError = true
          }


          if ( this.inValidItem(selectedKey.data_valid.gross)) {
            ErrorList = ErrorList + "<br>&bull; Gross Amount is empty, "
            isError = true
          }


          if (this.inValidItem(selectedKey.data_valid.dndate)) {
              ErrorList = ErrorList + "<br>&bull; Invoice Date is empty, "
              isError = true
          }

          if (this.inValidItem(selectedKey.data_valid.duedate) ) {
              ErrorList = ErrorList + "<br>&bull; Due date is empty, "
              isError = true
          }

          if (this.inValidItem(selectedKey.data_note)) {
              ErrorList = ErrorList + "<br>&bull; Internal Memo and BAPB date is empty, "
              isError = true
          }else {
              try {
                  let dtNote = JSON.parse(selectedKey.data_note)
                  if (this.inValidItem(dtNote.internal_memo)) {
                    dtNote.internal_memo = ""
                      // ErrorList = ErrorList + "<br>&bull; Internal Memo is empty, "
                      // isError = true
                  }
                  if (this.inValidItem(dtNote.bapb)) {
                      ErrorList = ErrorList + "<br>&bull; BAPB is empty, "
                      isError = true
                  }
                  if (!isError) {
                    selectedKey.data_note = JSON.stringify(dtNote)
                  }
              } catch (error) {
                 console.log(error)
              }
          }



        if (this.inValidItem(selectedKey.receive_at)) {
          ErrorList = ErrorList + "<br>&bull; Receive date is empty, "
          isError = true
        }
        // this.inValidItem(selectedKey.data_valid.invoice_group)  ||
        if (  this.inValidItem(selectedKey.data_valid.invoice_type) ) {
          ErrorList = ErrorList + "<br>&bull; Invoice type is empty, "
          isError = true
        }

        if (isError)  {
          break
        }
      }

      if (isError) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: `The selected item requires completing the necessary fields. Please edit it first.: ${ErrorList}`,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

      }else {
        // eslint-disable-next-line no-constant-condition

        const ids = this.selected.map(select => select.id);
        const dataValid = this.selected.map(select => select.data_valid);
        const dataBank = this.selected.map(select => select.data_bank);
        const dataSupplier = this.selected.map(select => select.data_supplier);
        const dataNote = this.selected.map(select => select.data_note);
        const dataPO = this.selected.map(select => select.data_po);
        let param = {
          id: ids,
          piID: dataPO,
          status: "release",
          data_valid: this.doJsonParse(dataValid),
          data_bank: this.doJsonParse(dataBank),
          data_note: this.doJsonParse(dataNote),
          data_supplier: this.doJsonParse(dataSupplier),
          data_all: this.selected,
        };
        this.$vs.loading();
        this.$http
            .put(`/api/v1/ocr/invoices`,param)
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code === 200) {
                this.handleClose();
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.getData(true);
            })
            .catch(() => {
              this.$vs.loading.close();
            });
        this.selected = [];
      }



    },
    closeRelease(){
      this.activePromptRelease = false
    },
    doDelete(){

      this.$vs.loading();
      this.$http
          .delete(`/api/v1/ocr/invoice/${this.piId}`,  {
            reason: this.reason
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    closeDelete(){
      this.piId = null;
      this.activePromptDelete = false;
    },
    deleteDraft(id){
      this.piId = id;
      this.activePromptDelete = true;
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val.ID)) {
        this.checked.splice(this.checked.indexOf(val.ID), 1);
        this.checkedCode = this.checkedCode.filter(v => {
          return v.ID != val.ID
        })
        // this.checkedCode.splice(this.checkedCode.indexOf(val.Code), 1);
      } else {
        this.checked.push(val.ID);
        this.checkedCode.push(val);
      }
    },
    toggleAll() {
      this.isAllSelected = !this.isAllSelected;
      console.log(this.isAllSelected);
    },
    selectAllToggle() {
      // this.selectedItems = this.tableData.map((row) => row.id); // Select all or none
      this.selectAll = !this.selectAll; // Toggle selectAll state
    },
    someActionSelected() {
      // Perform an action with the selected items
      console.log('Selected items:', this.selectedItems);
    },
    dateFormat(date) {
      if (date) {
        let inputDate = String(date).trim();

        // Format YYYY-MM-DD
        if (inputDate.includes("-")) {
            return moment(inputDate).format("YYYY-MM-DD");

          // Format DD/MM/YYYY
        } else if (inputDate.includes("/")) {
          const parts = inputDate.split("/");
          if (parts.length === 3) {
            return moment(`${parts[2]}-${parts[1]}-${parts[0]}`).format("YYYY-MM-DD");
          } else {
            return "";
          }

          // Format DD MMMM YYYY
        } else if (/\d{2}\s\w+\s\d{4}/.test(inputDate)) {
          return moment(inputDate, "DD MMMM YYYY").format("YYYY-MM-DD");

          // Format DDMMYYYY or similar
        } else {
          if (inputDate.length >= 8) {
            const day = inputDate.substr(0, 2);
            const month = inputDate.substr(2, 2);
            const year = inputDate.substr(4, 4);

            const formattedDate = `${year}-${month}-${day}`;
            return moment(formattedDate).format("YYYY-MM-DD");
          } else {
            return "";
          }
        }
      }
      return "";
    },
    dateFormatGlobal (date) {
      if (date) {
        const inputDate = date;
        const parts = inputDate.split("/");
        if (parts.length === 3) {
          return `${parts[2]}-${parts[1]}-${parts[0]}`;
        }else {
          return ""
        }
      }else {
        return ""
      }
    },
    checkDecimal(value) {
        if (this.isNumber(value)) {
            const parsedValue = parseFloat(value);
            if (this.isDecimal(parsedValue)) {
                return parsedValue.toFixed(2);
            } else {
                return  this.numberFormat(parsedValue) ;
            }
        } else {
            return  '0';
        }
    },
    isNumber(value) {
        return !isNaN(value) && value.trim() !== '';
    },
    isDecimal(value) {
      return value % 1 !== 0;
    },
    dateFormatFull(date) {
        // Periksa apakah input kosong atau null
        if (!date) {
            this.error = 'Input tidak boleh kosong atau null.';
            return '';
        }

        // Gunakan moment.js untuk parsing tanggal
        const parsedDate = moment(date);
        if (!parsedDate.isValid()) {
            this.error = 'Input bukan tanggal yang valid.';
            return '';
        }

        // Format tanggal menggunakan moment.js
        this.error = ''; // Clear any previous error message
        return parsedDate.format('YYYY-MM-DD HH:mm:ss');
    },
    cekTanggal() {
        this.hasil = this.dateFormatFull(this.tanggal);
    },
    dateFormatDot (date) {
      if (date) {
        const inputDate = date;
        // Check if the input date string has enough characters
        if (inputDate.length >= 8) {
          // Extract day, month, and year from the input string
          const day = inputDate.substr(0, 2);
          const month = inputDate.substr(2, 2);
          const year = inputDate.substr(4, 4);

          // Rearrange the parts to form the desired format
          const formattedDate = `${year}-${month}-${day}`;

          return  formattedDate
        } else {
          return ""
        }
      }else {
        return ""
      }
    },
    moneyFormat(money) {
      return this.priceFormat(money)
    },
    priceFormat: function (amount) {
      const newAmount = amount
        .toString()
        .replace(/[^.\d]/g, "")
        .toString();
      console.log(amount, newAmount);
      amount = parseFloat(newAmount).toFixed(2);
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberFormat(number) {
      // Periksa apakah input kosong, null, atau undefined
      if (number === null || typeof number === "undefined" || number === "") {
        return "0";
      }

      // Menghapus koma ribuan dan mengganti koma dengan titik untuk parsing angka
      let strNumber = number.toString().replace(/,/g, '').replace('.', ',');

      // Memeriksa apakah input adalah angka yang valid
      if (isNaN(strNumber.replace(',', '.'))) {
        return "0";
      }
      return parseFloat(strNumber)
        .toFixed(2)
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;

      this.getData(true);
    },
    handleChangePage(page) {
        this.statusSelectAll = this.check_page.includes(page);
        this.table.page = page;

      this.getData(false);
    },

    handleSort(key, active) {
        if (key != null) {
            console.log("key", key)
            this.table.order = key;
            this.table.sort = active;

            this.getData(true);
        }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData(false);
    },
    handleClose() {

      this.selected = [];
      this.getData(true);
      this.detail = false;
    },
    objectToKeyValueArray(obj) {
      var keyValueArray = [];
      if (obj===null) {
        return null
      }
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            var nestedKeyValueArray = this.objectToKeyValueArray(obj[key]);
            for (var i = 0; i < nestedKeyValueArray.length; i++) {
              var nestedKey = nestedKeyValueArray[i][0];
              var nestedValue = nestedKeyValueArray[i][1];
              keyValueArray.push([key + '.' + nestedKey, nestedValue]);
            }
          } else {
            keyValueArray.push([key, obj[key]]);
          }
        }
      }

      return keyValueArray;
    },
    shouldRemove(word) {
      let wordsToRemove = ["barang", "pajak", "harga", "jasa"];
      return wordsToRemove.includes(word);
    },
    findValueByPartialKey(arr, partialKey) {
      if (arr===null) {
        return  null
      }
      let filteredKeyValueArray = arr.filter(item => item[0].includes(partialKey));
      let arrs = filteredKeyValueArray.map(item => item[1]);
      return arrs.filter(item => item !== "" && !this.shouldRemove(item));
    },
    cleanString(valueArray, indexUse) {

      if (valueArray === null || valueArray.length === 0 || indexUse >= valueArray.length) {
        return "";
      }

      let value = valueArray[indexUse];
      return  value;
      /*if (value.trim() !== "") {
        return value.trim();
      } else {
        return "";
      }*/
    },
    removeNonAlphabeticCharacters(inputString) {
      // Use regular expression to replace non-alphabetic characters with an empty string
      return inputString.replace(/[^a-zA-Z]/g, '');
    },
      removeNonNumericCharacters(inputString) {
      // Use regular expression to replace non-numeric characters with an empty string
      return inputString.replace(/\D/g, '');
    },
      containsInteger(inputString) {
      return /\d/.test(inputString);
    },
    getData(cleanSelect) {
      if (cleanSelect===true) {
        this.selectAll = false;
        this.inv_id_select= []
        this.selected= []
        this.check_page= []
        this.statusSelectAll= false
      }

      this.$vs.loading();
      this.$http
        .get("/api/v1/ocr/data", {
          params: {
            limit: this.table.length==0 ? 5 : this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            supplier_code: this.supplier_code,
            from_date: this.from_inv_date,
            to_date: this.to_inv_date,
            status: this.tab_status,
          },
        })
        .then((resp) => {
          if (resp.code == "200") {
            this.table.total = resp.data.record_total;
            this.table.totalPage = resp.data.page_total;
            this.table.totalSearch = resp.data.record_total_search;
            this.table.length = resp.data.record_total_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            if (this.data !=null) {

              for (let i = 0; i < this.data.length; i++) {
                let dataJson = {}
                  dataJson =  JSON.parse(this.data[i]["data_json"].toLowerCase())

                this.data[i]["json_data"] = dataJson
                let objArray = this.objectToKeyValueArray(dataJson)
                if (this.data[i]["data_valid"].length <10) {
                  let dataPOArray =  this.cleanString(this.findValueByPartialKey(objArray, 'header-1.po'), 0);
                  let dataPO =dataPOArray.split(" ");
                  let allArayItem = [];
                  let allProductItem = [];
                  let isInvoice = false;
                  if (dataJson !==null) {
                      for (let dataJsonElement of dataJson) {
                          let arrayItem = []
                          if (dataJsonElement["type"].toLowerCase()==="invoice")
                          {
                              arrayItem = dataJsonElement["data"] ? dataJsonElement["data"]["items"] : [];
                              allArayItem = allArayItem.concat(arrayItem);
                              isInvoice = true
                          }
                      }
                  }

                  // eslint-disable-next-line no-constant-condition
                  if (isInvoice && allArayItem.length!==0 && false ){
                    for (const allArayItemElement of allArayItem) {
                      if (allArayItemElement==null) {
                        continue
                      }


                      let inputString = allArayItemElement["item"];
                      if (typeof inputString === 'string') {


                        const parts = inputString.split(' ');
                        const productId = parts.shift();
                        const namaProduk = parts.slice(0, parts.findIndex(part => part === 'discount')).join(' ');
                        const arrayNamaProduk = namaProduk.toString().split(" ");


                        const amount = arrayNamaProduk[arrayNamaProduk.length-1];
                        const price = arrayNamaProduk[arrayNamaProduk.length-2];
                        let qtyStr = arrayNamaProduk[arrayNamaProduk.length-3];
                        let unitStr = arrayNamaProduk[arrayNamaProduk.length-4];
                        let endIndex = arrayNamaProduk.length-4;
                        if (this.containsInteger(qtyStr)){
                          unitStr = this.removeNonNumericCharacters(qtyStr);
                          qtyStr = this.removeNonAlphabeticCharacters(qtyStr);
                          endIndex = arrayNamaProduk.length-3;
                        }

                        let fixName =  arrayNamaProduk.slice(0, endIndex);
                        let namaProdukFix = fixName.join(" ")

                        const discount = parts[parts.findIndex(part => part === 'discount') + 1].replaceAll("%","");
                        const priceDiscount = parts[parts.length-1].replaceAll("(","").replaceAll(")","");

                        let itemProduct ={
                          'product_id': productId,
                          'item_name': namaProdukFix,
                          'quantity': unitStr,
                          'item_unit': qtyStr,
                          'price': price,
                          'tax': 0,
                          'amount': amount,
                          'discount': discount,
                          'price_discount': priceDiscount,
                        }
                        allProductItem = allProductItem.concat(itemProduct);
                      } else {
                        // console.log('Input is not a string : ',inputString);
                      }
                    }
                  }
                  this.data[i]["data_valid"] = {
                    invoice_balance: this.cleanString(this.findValueByPartialKey(objArray, 'footer-2.invoice_balance'), 0),
                    invoice_type: "invoice",
                    invoice_group: true,
                    invoice_number: "",
                    supplier_id: null,
                    sub_total: this.cleanString(this.findValueByPartialKey(objArray, 'footer-2.sub_total'), 0),
                    tax: this.cleanString(this.findValueByPartialKey(objArray, 'footer-2.tax'), 0),
                    dn: this.cleanString(this.findValueByPartialKey(objArray, 'header-1.dn'), 0),
                    dndate: this.dateFormat( this.cleanString(this.findValueByPartialKey(objArray, 'header-1.dndate'), 0)),
                    duedate: this.dateFormat(this.cleanString(this.findValueByPartialKey(objArray, 'header-1.duedate'), 0)),
                    no: this.cleanString(this.findValueByPartialKey(objArray, 'header-1.no'), 0),
                    shipto: this.cleanString(this.findValueByPartialKey(objArray, 'header-1.shipto'), 0),
                    po: dataPO[0] ,
                    nomor_seri: this.cleanString(this.findValueByPartialKey(objArray, 'header.kode.nomor_seri'), 0),
                    pembeli_alamat: this.cleanString(this.findValueByPartialKey(objArray, 'header.pembeli.alamat'), 0),
                    pembeli_nama: this.cleanString(this.findValueByPartialKey(objArray, 'header.pembeli.nama'), 0),
                    pembeli_npwp: this.cleanString(this.findValueByPartialKey(objArray, 'header.pembeli.npwp'), 0),
                    pengusaha_alamat: this.cleanString(this.findValueByPartialKey(objArray, 'header.pengusaha.alamat'), 0),
                    pengusaha_nama: this.cleanString(this.findValueByPartialKey(objArray, 'header.pengusaha.nama'), 0),
                    pengusaha_npwp: this.cleanString(this.findValueByPartialKey(objArray, 'header.pengusaha.npwp'), 0),
                    dph: this.cleanString(this.findValueByPartialKey(objArray, 'footer-1.dph'), 0),
                    dpp: this.cleanString(this.findValueByPartialKey(objArray, 'footer-1.dpp'), 0),
                    penggantian: this.cleanString(this.findValueByPartialKey(objArray, 'footer-1.penggantian'), 0),
                    gross: this.cleanString(this.findValueByPartialKey(objArray, 'footer-2.gross'), 0),
                    product_item : allProductItem,
                    pic_name : "",
                    pic_phone: "",
                    tax_date: "",
                    po_date: "",
                    external_code: "",
                    barcode: "",
                    createdDate: "",
                    note: "",
                  };
                }else{
                    try{
                        this.data[i]["data_valid"] = JSON.parse(this.data[i]["data_valid"]);
                    }catch {
                        this.data[i]["data_valid"]= {};
                    }
                }
                  try {
                      this.data[i]["data_valid"]["pic"] = JSON.parse(this.data[i]["data_pic"]);
                  }catch {
                      this.data[i]["data_valid"]["pic"] = {};
                  }
              }
            }
          }
          this.setStartEnd();
          this.$vs.loading.close();
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    isJsonString(jsonData){
      if (!jsonData) return  {}
       if (jsonData.length > 2) {
            try {
                return JSON.parse(jsonData);
            }catch  {
                return {}
            }
       }
       return {}
    },
    handleEdit(
      id,
      index,
      dataValid,
      dataJson,
      fileName,
      pageCount,
      dataItem,
      dataAll
    ) {
      this.piId = id;
      this.table.data = [];
      this.table.data = this.data;
      this.dataValid = dataValid;
      this.dataJson = dataJson;
      this.pageCount = pageCount;
      this.fileName = fileName;
      this.dataItem = dataItem;
      this.dataAll = dataAll;
      if (!this.dataAll["priority"] || this.dataAll["priority"]==="undefined") {
        this.dataAll["priority"] = "";
      }
      if (!this.dataAll["refer_to"]) {
        this.dataAll["refer_to"] = 0;
      }
      this.dataValid["refers_to"] = this.isJsonString(dataValid["refers_to"]);
      this.dataAll["supplier"] = this.isJsonString(dataAll["data_supplier"]);
      this.dataAll["data_bank"] = this.isJsonString(dataAll["data_bank"]);
      this.dataAll["pic"] = this.isJsonString(dataAll["data_pic"]);
      this.dataAll["data_note"] = this.isJsonString(dataAll["data_note"]);
      if (dataAll["data_note"]) {
        if (this.dataAll["data_note"]["receiving_date"] && this.dataAll["data_note"]["receiving_date"].toString().length < 3) {
          this.dataAll["data_note"]["receiving_date"] = dataAll["created_at"];
        }else {
          this.dataAll["data_note"]["receiving_date"] = dataAll["created_at"];
        }
      } else {
        this.dataAll["data_note"]["receiving_date"] = dataAll["created_at"];
      }
      this.dataAll["data_qr"] =this.isJsonString(dataAll["data_qr"]);
      this.dataAll["qr_link"] = (dataAll["qr_link"]);
      this.dataAll["barcode"] = (dataAll["barcode"]);

      // console.log(dataAll,"dataAll");

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
  },
  mounted() {
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}
</style>
